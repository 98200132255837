import * as React from 'react';
import { graphql } from 'gatsby';
import Image, { FluidObject } from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import tw from 'twin.macro';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { models } from '../../models';
import { seoData } from '../data/seo';
import { defaultLangKey } from '../data/languages';
import LearnMore from '../components/learnMore';
import ContentWidth from '../components/contentWidth';

type AboutNode = {
  id: string;
  advantages: {
    title: string;
    list: {
      title: string[];
      description: string[];
      mainImage: { childImageSharp: { fluid: FluidObject } };
      smallImage: { childImageSharp: { fluid: FluidObject } };
    }[];
  };
  explanation: {
    title: string[];
    description: string[];
    leftImage: { childImageSharp: { fluid: FluidObject } };
    mapImage: { childImageSharp: { fluid: FluidObject } };
  };
  top: {
    title: string;
    description: string[];
  };
  profile: {
    title: string;
    description: string[];
    image: { childImageSharp: { fluid: FluidObject } };
    name: string;
    motto: {
      title: string;
      description: string[];
    };
  };
};

type Props = {
  location: Location;
  data: {
    about: {
      edges: [{ node: AboutNode }];
    };
  };
};

function Section({
  children,
  direction,
}: {
  children: any;
  direction: 'column' | 'row';
}) {
  const classes = [
    tw`flex flex-col lg:h-80vh lg:my-10vh mx-0 mb-16 md:mb-24 lg:mb-0`,
    direction === 'row' ? tw`lg:flex-row` : tw`flex flex-col justify-around`,
  ];

  return <section css={classes}>{children}</section>;
}

function About({ location, data: { about } }: Props) {
  return (
    <Layout
      lang={defaultLangKey}
      location={location}
      pathnameWithoutLang={models.pages!.about.path}
    >
      <SEO
        featuredImage={seoData.about.featuredImage?.[defaultLangKey]}
        title={seoData.about.title[defaultLangKey]}
        description={seoData.about.description[defaultLangKey]}
        lang={defaultLangKey}
        pathname={location.pathname}
      />
      <ContentWidth>
        {about.edges.map(({ node }: { node: AboutNode }) => (
          <div key={node.id}>
            <section
              css={tw`flex flex-col justify-center -mt-12 md:-mt-24`}
              style={{ height: 'calc(100vh - 72px)' }}
            >
              <h1
                css={tw`text-title-italic font-bold text-2xl md:text-4xl lg:text-5xl pb-8 text-center`}
              >
                {node.top.title}
              </h1>
              <span
                css={tw`block bg-gray-200 w-1/4 mx-auto mb-8`}
                style={{ height: '1px' }}
              />
              <div
                css={tw`text-center font-bold text-sm lg:text-2xl leading-loose`}
              >
                {node.top.description.map((line) => (
                  <p key={line}>{line}</p>
                ))}
              </div>
              <LearnMore />
            </section>
            <Section direction="row">
              <BackgroundImage
                Tag="div"
                fluid={node.explanation.leftImage.childImageSharp.fluid}
                css={tw`w-full lg:max-w-md hidden lg:block`}
              />
              <div css={tw`flex flex-col lg:p-12 justify-between`}>
                <h2
                  css={tw`text-2xl md:text-3xl lg:text-4xl mb-10 lg:mb-0 font-bold`}
                >
                  {node.explanation.title.map((line) => (
                    <span key={line} css={tw`inline-block`}>
                      {line}
                    </span>
                  ))}
                </h2>
                <div
                  css={tw`text-sm md:text-lg lg:px-4 font-bold leading-relaxed lg:leading-loose mb-10 lg:mb-0`}
                >
                  {node.explanation.description.map((line) =>
                    !!line ? <p>{line}</p> : <br />
                  )}
                </div>
                <div css={tw`relative pb-9/16`}>
                  <iframe
                    css={tw`absolute top-0 left-0 right-0 bottom-0 w-full h-full`}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6404.32167755835!2d139.76462839666087!3d35.69408937451938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c03c7452b23%3A0x3ac3b068c835da49!2z44CSMTAxLTAwNDYg5p2x5Lqs6YO95Y2D5Luj55Sw5Yy656We55Sw5aSa55S677yS5LiB55uu77yR!5e0!3m2!1sja!2sjp!4v1586044215679!5m2!1sja!2sjp"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen={true}
                    aria-hidden={'false'}
                    tabIndex={0}
                  />
                </div>
              </div>
            </Section>
            <Section direction="column">
              <h2
                css={tw`text-4xl font-bold text-center mt-24 mb-6 md:mb-12 lg:mb-0`}
              >
                {node.advantages.title}
              </h2>
              <ul css={tw`justify-between items-center hidden md:flex`}>
                {node.advantages.list.map((item, index) => (
                  <li key={index}>
                    <figure>
                      <Image
                        fluid={item.smallImage.childImageSharp.fluid}
                        css={tw`rounded-full`}
                        style={{ width: '200px', height: '200px' }}
                      />
                      <figcaption
                        css={tw`text-center font-bold leading-relaxed mt-6`}
                      >
                        {item.title.map((line) => (
                          <p key={line}>{line}</p>
                        ))}
                      </figcaption>
                    </figure>
                  </li>
                ))}
              </ul>
              <div css={tw`mb-24`}>
                <LearnMore />
              </div>
            </Section>
            <section>
              {node.advantages.list.map((item, index) => (
                <Section direction="row" key={`about-section-${index}`}>
                  <div
                    css={tw`relative pb-9/16 w-full lg:max-w-md block order-1 lg:order-none h-full`}
                  >
                    <BackgroundImage
                      Tag="div"
                      fluid={item.mainImage.childImageSharp.fluid}
                      css={tw`absolute top-0 left-0 right-0 bottom-0 w-full h-full`}
                      style={{ position: 'absolute' }}
                    />
                  </div>
                  <div css={tw`lg:p-12 mb-12 lg:mb-0`}>
                    <h2 css={tw`text-2xl lg:text-4xl font-bold mb-6 lg:mb-24`}>
                      {item.title}
                    </h2>
                    <div css={tw`font-bold leading-loose text-base lg:text-lg`}>
                      {item.description.map((line) => (
                        <p>{line}</p>
                      ))}
                    </div>
                  </div>
                </Section>
              ))}
            </section>
            <Section direction="column">
              <h2
                css={tw`text-3xl md:text-4xl font-bold mt-12 md:mt-24 mb-8 md:mb-0`}
              >
                {node.profile.title}
              </h2>
              <div css={tw`md:flex`}>
                <div css={tw`md:w-2/5`}>
                  <figure>
                    <Image fluid={node.profile.image.childImageSharp.fluid} />
                    <figcaption css={tw`text-lg font-bold my-6`}>
                      {node.profile.name}
                    </figcaption>
                  </figure>
                  {/*<div>*/}
                  {/*  {node.profile.description.map((line) => (*/}
                  {/*    <p css={tw`font-bold text-xs`} key={line}>*/}
                  {/*      {line}*/}
                  {/*    </p>*/}
                  {/*  ))}*/}
                  {/*</div>*/}
                </div>
                <div css={tw`mt-12 md:mt-0 md:w-3/5 bg-gray-200 p-8 md:ml-12`}>
                  <h3 css={tw`text-2xl md:text-3xl font-bold mb-8`}>
                    『{node.profile.motto.title}』
                  </h3>
                  <div css={tw`text-base md:text-lg font-bold leading-loose`}>
                    {node.profile.motto.description.map((line) => (
                      <p css={tw`mb-6 last:mb-0`} key={line}>
                        {line}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </Section>
          </div>
        ))}
      </ContentWidth>
    </Layout>
  );
}

export const aboutQuery = graphql`
  query AboutQuery {
    about: allAboutJson {
      edges {
        node {
          id
          advantages {
            title
            list {
              mainImage {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              smallImage {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              description
              title
            }
          }
          explanation {
            title
            description
            leftImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mapImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          top {
            title
            description
          }
          profile {
            title
            description
            name
            motto {
              title
              description
            }
            image {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default About;
