import * as React from 'react';
import tw from 'twin.macro';

const LearnMore = () => (
  <div css={tw`text-center mt-6 md:mt-12`}>
    <p css={tw`text-title-italic text-lg font-bold`}>Learn More</p>
  </div>
);

export default LearnMore;
